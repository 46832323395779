import classNames from 'classnames'
import React, { FC, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { getAmmConfigById } from '../config/amm'
import { financialFormat } from '../helpers/priceAndAmountHelpers'
import { useComponentDidMount } from '../hooks/useComponentDidMount'
import { PoolItem } from '../model'
import { State } from '../reducers'
import { getCurrencyName, getMarketDisplayNameBySymbols } from '../utils'
import IconTokenComponent from './IconTokenComponent'
import { ClickPoolAddressHandler } from './PoolActivity/useTradeHistoryFilter'
import IconAmm from './TraderProfile/IconAmm'

interface ChartTreeMapElementProps {
  pool: PoolItem
  value?: number
  rect: { left: number; top: number; width: number; height: number }
  onClickPoolAddress: ClickPoolAddressHandler
}

const ChartTreeMapElement: FC<ChartTreeMapElementProps> = ({
  pool,
  value,
  rect,
  onClickPoolAddress,
}) => {
  const selectedCurrency = useSelector((state: State) => state.currency)
  const labelRef = useRef<HTMLDivElement | null>(null)
  const labelWrapperRef = useRef<HTMLDivElement | null>(null)
  const labelContentRef = useRef<HTMLDivElement | null>(null)
  const labelBodyRef = useRef<HTMLDivElement | null>(null)
  const labelValueRef = useRef<HTMLDivElement | null>(null)
  const labelIconsRef = useRef<HTMLDivElement | null>(null)
  const labelSymbolRef = useRef<HTMLDivElement | null>(null)
  const tooltipRef = useRef<HTMLDivElement | null>(null)

  const amm = getAmmConfigById(pool.amm)

  const style = {
    '--treemap-element-border': amm.borderColor || '',
    '--treemap-element-background': amm.background || '',
    left: `${rect.left}%`,
    top: `${rect.top}%`,
    right: `${100 - rect.left - rect.width}%`,
    bottom: `${100 - rect.top - rect.height}%`,
  } as React.CSSProperties

  const calcResize = (): void => {
    const label = labelRef.current
    if (!label) {
      return
    }

    const wrapper = labelWrapperRef.current
    const content = labelContentRef.current
    const contentBody = labelContentRef.current
    const contentValue = labelValueRef.current
    const contentIcons = labelIconsRef.current
    const contentSymbol = labelSymbolRef.current

    if (!wrapper || !content || !contentBody || !contentValue || !contentIcons || !contentSymbol) {
      return
    }

    if (wrapper?.offsetHeight < 20 || wrapper?.offsetHeight < 20) {
      label.className = 'treemap-element treemap-element--pico'
    } else {
      label.className = 'treemap-element treemap-element--lg'
      const sizes = ['lg', 'md', 'sm', 'xs', 'tiny', 'micro']

      for (let index = 1; index < sizes.length; index++) {
        const greaterHeight =
          content.clientHeight > wrapper.offsetHeight || content.clientHeight < 75
        const greaterWidth =
          Math.max(contentValue.clientWidth, contentIcons.clientWidth, contentSymbol.clientWidth) >
          contentBody.offsetWidth
        if (greaterHeight || greaterWidth) {
          label.className = `treemap-element treemap-element--${sizes[index]}`
        } else {
          break
        }
      }
    }
  }

  useComponentDidMount(() => {
    calcResize()

    window.addEventListener('resize', calcResize)

    return (): void => {
      window.removeEventListener('resize', calcResize)
    }
  })

  useEffect(() => {
    calcResize()
  }, [rect])

  const eventMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    const tooltip = tooltipRef.current

    if (!tooltip) {
      return
    }

    const label = e.currentTarget
    const labelRect = label.getBoundingClientRect()
    const tooltipRect = tooltip.getBoundingClientRect()
    const parent = label.parentNode?.parentNode as HTMLElement
    let diffX = tooltipRect.width
    let diffY = tooltipRect.height
    if (parent) {
      const parentRect = parent?.getBoundingClientRect()

      if (e.clientX - parentRect.x < tooltipRect.width) {
        diffX = 0
      }

      if (e.clientY - parentRect.y <= tooltipRect.height) {
        diffY = e.clientY - parentRect.y
      }
    }
    const x = e.clientX - labelRect.x - diffX
    const y = e.clientY - labelRect.y - diffY

    tooltip.style.transform = `translate(${x}px,${y}px)`
  }

  const currencyDisplayName = getCurrencyName(pool.network, selectedCurrency)

  const feeRateMessage =
    amm.name === 'uniswap_v3' && pool.feeTier ? ` – ${pool.feeTier / 10000}%` : ''

  const handleClickPoolAddress = (): void => {
    if (!pool.id) {
      return
    }
    const poolAddress = pool.id?.split('-')[0]
    onClickPoolAddress(poolAddress, amm.name)
  }

  const tokens = pool.tokenAddresses.map((address, idx) => {
    return {
      address: address,
      symbol: pool.symbols[idx],
      logoURI: pool.logoURIs[idx],
    }
  })

  const symbols = getMarketDisplayNameBySymbols(pool)

  return (
    <>
      <div
        className="treemap-element treemap-element--lg"
        style={style}
        ref={labelRef}
        onClick={handleClickPoolAddress}>
        <div
          className="treemap-element__wrapper"
          ref={labelWrapperRef}
          onMouseMove={eventMouseMove}>
          <div className="treemap-element__content" ref={labelContentRef}>
            <div className="treemap-element__header">
              <div className="treemap-element__amm">
                <IconAmm {...amm} />
              </div>
            </div>
            <div className="treemap-element__body" ref={labelBodyRef}>
              <div className="treemap-element__icons" ref={labelIconsRef}>
                {tokens.map((token) => {
                  return (
                    <div className="token-ico" key={token.address}>
                      <IconTokenComponent
                        iconToken={token.logoURI}
                        symbol={token.symbol}
                        className="token-ico__image"
                      />
                    </div>
                  )
                })}
              </div>
              <div className="treemap-element__symbols" ref={labelSymbolRef}>
                {symbols}
                {feeRateMessage}
              </div>
              <div className="treemap-element__value" title={`$${value}`} ref={labelValueRef}>
                {selectedCurrency === 'USD' ? <span className="sign">$</span> : null}
                <span className="value">
                  {financialFormat(value || 0, {
                    roundBigNumbersFrom: 'K',
                  })}
                </span>
                {selectedCurrency !== 'USD' ? (
                  <span className="sign">&nbsp;{currencyDisplayName}</span>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div className="treemap-tooltip" ref={tooltipRef}>
          <div className="treemap-tooltip__amm">
            <div className="treemap-tooltip__amm-icon">
              <IconAmm {...amm} size="sm" />
            </div>
            <div className="treemap-tooltip__amm-title">
              {amm.displayName}
              {feeRateMessage}
            </div>
          </div>
          <div
            className={classNames('treemap-tooltip__pool', {
              'treemap-tooltip__pool--uneven': tokens.length > 2,
            })}>
            <div className="treemap-tooltip__icons">
              {tokens.map((token) => {
                return (
                  <div className={`token-ico`} key={token.address}>
                    <IconTokenComponent
                      iconToken={token.logoURI}
                      symbol={token.symbol}
                      className="token-ico__image"
                    />
                  </div>
                )
              })}
            </div>
            <div className="treemap-tooltip__symbols">{symbols}</div>
          </div>
          <div className="treemap-tooltip__value" title={`$${value}`}>
            {selectedCurrency === 'USD' ? <span className="sign">$</span> : null}
            <span className="value">
              {financialFormat(value || 0, {
                roundBigNumbersFrom: 'K',
              })}
            </span>
            {selectedCurrency !== 'USD' ? (
              <span className="sign">&nbsp;{currencyDisplayName}</span>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default ChartTreeMapElement
