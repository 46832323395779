import React, { FC, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import squarify from 'squarify'

import { PoolItem } from '../model'
import { State } from '../reducers'
import { getMarketDisplayNameBySymbols, pickValueBasedOnCurrency } from '../utils'
import ChartTreeMapElement from './ChartTreeMapElement'
import { ClickPoolAddressHandler } from './PoolActivity/useTradeHistoryFilter'

interface ChartTreeMapProps {
  data: PoolItem[]
  onClickPoolAddress: ClickPoolAddressHandler
}

interface SquarifyItem {
  value: number
  data: PoolItem
  x0: number
  y0: number
  x1: number
  y1: number
}

const ChartTreeMap: FC<ChartTreeMapProps> = ({ data, onClickPoolAddress }) => {
  const labelsRef = useRef<HTMLDivElement | null>(null)
  const currency = useSelector((state: State) => state.currency)
  const [treeMapData, setTreeMapData] = useState<SquarifyItem[] | undefined>()
  const sidebarOpen = useSelector((state: State) => state.ui.isSidebarOpen)

  useEffect(() => {
    const chartData: { value: number; data: PoolItem }[] = data.map((item: PoolItem) => {
      const value =
        sidebarOpen === 'history'
          ? pickValueBasedOnCurrency(currency, item?.volume24hStable, item?.volume24hNative)
          : pickValueBasedOnCurrency(currency, item?.liquidityStable, item?.liquidityNative)
      return {
        value: value,
        data: item,
      }
    })

    const output = squarify(chartData, {
      x0: 0,
      y0: 0,
      x1: 100, // used as 100% for percent-based layout
      y1: 100, // used as 100% for percent-based layout
    })

    setTreeMapData(output)
  }, [data, currency, sidebarOpen])

  return (
    <div className="chart-container">
      <div className="treemap-labels" ref={labelsRef}>
        {treeMapData?.map((item: SquarifyItem) => {
          return (
            <ChartTreeMapElement
              key={`${item.data.id}-${getMarketDisplayNameBySymbols(item.data)}-${
                item.data.network
              }`}
              pool={item.data}
              value={item.value}
              rect={{
                left: item.x0,
                top: item.y0,
                width: item.x1 - item.x0,
                height: item.y1 - item.y0,
              }}
              onClickPoolAddress={onClickPoolAddress}
            />
          )
        })}
      </div>
    </div>
  )
}

export default ChartTreeMap
