import { Chart as ChartJS, ChartData } from 'chart.js'
import { FC, useRef } from 'react'
import { Chart } from 'react-chartjs-2'

import { datasetsToLogarithmicBase, getChartBackground, getChartOptionsCompact } from './helpers'
import { pluginHoverVerticalLine } from './plugins'

const ChartCompact: FC<{
  data: number[]
  labels: number[]
  color: string
  bg: string[]
  currency: string
  logarithmic?: boolean
}> = ({ data, labels, color, bg, currency, logarithmic }) => {
  const chartRef = useRef<ChartJS>(null)
  const chartTooltipRef = useRef<HTMLDivElement>(null)

  const chartOptions = getChartOptionsCompact({ chartTooltipRef, currency, logarithmic })

  const chartData: ChartData = {
    labels: labels,
    datasets: [
      {
        fill: 'start',
        backgroundColor: getChartBackground(bg, chartRef?.current),
        borderColor: color,
        borderWidth: 3,
        pointHoverBackgroundColor: '#181D23',
        pointHoverBorderColor: color,
        pointHoverBorderWidth: 3,
        pointHoverRadius: 5,
        pointRadius: 0,
        hitRadius: 15,
        tension: 0.4,
        data: data,
      },
    ],
  }

  if (logarithmic) {
    chartData.datasets = datasetsToLogarithmicBase(chartData.datasets)
  }

  return (
    <div className="chart-container">
      <Chart
        type="line"
        ref={chartRef}
        data={chartData}
        options={chartOptions}
        plugins={[pluginHoverVerticalLine]}
      />
      <div ref={chartTooltipRef} className="chartjs-tooltip chartjs-tooltip--compact" />
    </div>
  )
}

export default ChartCompact
