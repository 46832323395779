import { Chart as ChartJS, ChartData, ChartOptions, InteractionItem } from 'chart.js'
import React, { FC, MouseEvent, useRef } from 'react'
import { Chart, getElementsAtEvent } from 'react-chartjs-2'

import { ChartTooltipOptions } from '../../model'
import { datasetsToLogarithmicBase, getChartOptionsDetailed } from './helpers'
import { pluginBarValueSort, pluginHoverVerticalLine } from './plugins'

interface ChartDetailedProps {
  type?: 'line' | 'bar'
  stacked?: boolean
  data: ChartData
  logarithmic?: boolean
  currency?: string // USD or native currency: ETH | BNB | MATIC etc.
  options?: ChartOptions
  tooltipOptions?: ChartTooltipOptions
  gridColor?: string
  labelColor?: string
  isTotal: boolean
  period?: string
  onClick?: (element: InteractionItem[]) => void
}

const ChartDetailed: FC<ChartDetailedProps> = ({
  type = 'line',
  stacked = true,
  data,
  logarithmic,
  currency,
  options,
  tooltipOptions,
  gridColor,
  labelColor,
  isTotal,
  period,
  onClick,
}) => {
  const chartRef = useRef<ChartJS>(null)

  const chartTooltipRef = useRef<HTMLDivElement>(null)

  const chartOptions = getChartOptionsDetailed(
    {
      type,
      stacked,
      gridColor,
      labelColor,
      chartTooltipRef,
      tooltipOptions,
      currency,
      logarithmic,
      isTotal,
      period,
    },
    options
  )

  const chartData = data

  if (logarithmic) {
    chartData.datasets = datasetsToLogarithmicBase(chartData.datasets)
  }

  const handleClick = (event: MouseEvent<HTMLCanvasElement>): void => {
    if (chartRef.current && onClick) {
      onClick(getElementsAtEvent(chartRef.current, event))
    }
  }

  return (
    <div className="chart-container">
      <Chart
        type={type}
        ref={chartRef}
        data={chartData}
        options={chartOptions}
        plugins={type === 'bar' ? [pluginBarValueSort] : [pluginHoverVerticalLine]}
        onClick={onClick ? handleClick : undefined}
      />
      <div ref={chartTooltipRef} className="chartjs-tooltip"></div>
    </div>
  )
}

export default ChartDetailed
